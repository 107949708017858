/*-HTML5 Reset :: style.css---------------------------------------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

article, aside, figure, footer, header, nav, section, details, summary {
  display: block;
}

/* Handle box-sizing while better addressing child elements:
   http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

img,
object,
embed {
  max-width: 100%;
}

img { /*display:block;*/
  vertical-align: bottom;
}

/* force a vertical scrollbar to prevent a jumpy page */
/* we use a lot of ULs that aren't bulleted.
	don't forget to restore the bullets within content. */
ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: bold;
  vertical-align: bottom;
}

td {
  font-weight: normal;
  vertical-align: top;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

pre {
  white-space: pre; /* CSS2 */
  white-space: pre-wrap; /* CSS 2.1 */
  white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word; /* IE */
}

input[type=radio] {
  vertical-align: text-bottom;
}

input[type=checkbox] {
  vertical-align: bottom;
}

.ie7 input[type=checkbox] {
  vertical-align: baseline;
}

.ie6 input {
  vertical-align: text-bottom;
}

select, input, textarea {
  font: 99% sans-serif;
}

table {
  font-size: inherit;
  font: 100%;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

td, td img {
  vertical-align: top;
}

/* Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif;
}

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer;
}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0;
}

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible;
}

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic;
}

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/
/* let's clear some floats */
.clearfix:before, .clearfix:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

li.clearfix {
  display: list-item;
}

/*Hide these rules from IE-mac */
* html .clearfix {
  height: 1%;
}

.clearfix {
  *zoom: 1;
}

/*-End HTML5 Reset :: style.css------------------------------------------------------------------------------------------*/
.hidden, .invisible {
  visibility: hidden;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.boldcase {
  font-weight: 700;
}

.normalcase {
  font-weight: 400;
}

.textdec-underline {
  text-decoration: underline;
}

.textdec-normal, a {
  text-decoration: none;
}

.Text-Idnt {
  text-indent: -99999px;
}

.hidden {
  display: none !important;
}

.visuallyhidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

img {
  border: 0;
  height: auto;
  max-width: 100%;
}

img.floatleft {
  float: left;
  margin: 0 10px 0 0;
}

img.floatright {
  float: right;
  margin: 0 0 0 10px;
}

.floatleft {
  float: left;
}

.floatright {
  float: right;
}

a {
  color: #000;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

a:hover {
  text-decoration: underline;
}

p:last-child {
  padding-bottom: 0 !important;
}

@keyframes smooth-entry {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-1.8em);
    -moz-transform: translateY(-1.8em);
    -ms-transform: translateY(-1.8em);
    -o-transform: translateY(-1.8em);
    transform: translateY(-1.8em);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
body {
  background: #fff;
  font: normal 100%/1.5 "Roboto", Arial, Helvetica, sans-serif;
  color: #fff;
  font-weight: 300;
  margin: 0;
  text-align: left;
}

.wrapper {
  position: relative;
  width: 100%;
  max-width: 80em; /*1280/16*/
  padding: 0 15px;
  margin: 0 auto;
}

.container {
  width: 100%;
  background: #fff;
  padding-bottom: 50px;
}

.headerCallNumber {
  position: absolute;
  top: 15px;
  right: 30px;
}
@media (max-width: 74.9375em) {
  .headerCallNumber {
    top: 0;
    right: 0;
    width: 100%;
    text-align: center;
    background: #fff3db;
    padding: 5px;
    border-bottom: 1px solid #ebdec6;
  }
}
@media (max-width: 63.9375em) {
  .headerCallNumber {
    display: none !important;
  }
}
.headerCallNumber a {
  text-decoration: none !important;
}
.headerCallNumber i {
  max-width: 20px;
  display: inline-block;
  margin-right: 0;
}
.headerCallNumber i img {
  vertical-align: text-top;
  margin-top: -2px;
}
.headerCallNumber span {
  font-size: 20px;
  color: #783d19;
}

.pageHeader {
  background: #5b6267;
  border-bottom: 2px solid #fff;
}
@media (max-width: 33.6875em) {
  .pageHeader .wrapper {
    padding: 0;
  }
}
.pageHeader .headerTop {
  padding: 35px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 33.6875em) {
  .pageHeader .headerTop {
    padding: 20px 0 0;
  }
}
@media (max-width: 63.9375em) {
  .pageHeader .headerTop .logoWrap {
    max-width: 165px;
  }
}
@media (max-width: 33.6875em) {
  .pageHeader .headerTop .logoWrap {
    max-width: 100%;
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 15px;
  }
  .pageHeader .headerTop .logoWrap img {
    max-width: 165px;
  }
}
@media (max-width: 29.9375em) {
  .pageHeader .headerTop .logoWrap a {
    max-width: 250px;
    display: block;
    margin: auto;
  }
}
.pageHeader .headerTop .headerRight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 63.9375em) {
  .pageHeader .headerTop .headerRight {
    justify-content: center;
  }
}
@media (max-width: 33.6875em) {
  .pageHeader .headerTop .headerRight {
    display: none;
    max-width: 100%;
    flex-basis: 100%;
    background: #2f2f2f;
    padding: 15px 0;
  }
}
.pageHeader .headerTop .headerRight .headerNumberWrap {
  text-align: right;
  padding-right: 18px;
}
@media (max-width: 33.6875em) {
  .pageHeader .headerTop .headerRight .headerNumberWrap {
    padding: 0 0 0 0;
    text-align: center;
  }
}
.pageHeader .headerTop .headerRight .headerNumberWrap .headerNumber {
  font-size: 24px;
  font-size: 1.5rem;
  color: #bfc5c6;
  line-height: 1;
  text-decoration: none;
}
.pageHeader .headerTop .headerRight .headerNumberWrap .headerNumber span {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  display: block;
}
.mainNavigation {
  position: relative;
}
@media (max-width: 63.9375em) {
  .mainNavigation {
    display: none;
  }
}
.mainNavigation ul {
  text-align: right;
}
@media (max-width: 74.9375em) {
  .mainNavigation ul {
    text-align: center;
  }
}
.mainNavigation ul li {
  display: inline-block;
}
.mainNavigation ul li.current-menu-item a {
  background: #783d19;
  color: #fff;
}
.mainNavigation ul li:hover a {
  background: #783d19;
  color: #fff;
}
.mainNavigation ul li:hover ul {
  opacity: 1;
  display: flex;
  z-index: 100;
}
.mainNavigation ul li a {
  position: relative;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 500;
  color: #bfc5c6;
  padding: 10px 20px;
  text-decoration: none;
  text-align: left;
}
@media (max-width: 74.9375em) {
  .mainNavigation ul li a {
    padding: 8px 20px;
  }
}
.mainNavigation ul li a.current-menu-item {
  background: #bfc5c6;
  color: #000;
}
.mainNavigation ul li a:hover {
  background: #bfc5c6;
  color: #000;
}
.mainNavigation ul li a:hover ul {
  opacity: 1;
  display: flex;
  z-index: 100;
}
.mainNavigation ul li ul {
  position: absolute;
  right: 0;
  width: 100%;
  background: #783d19;
  padding: 20px;
  list-style: none;
  text-align: left;
  z-index: 100;
  display: flex;
  flex-wrap: wrap;
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: -100;
}
.mainNavigation ul li ul li {
  max-width: 50%;
  flex-basis: 50%;
}
@media (max-width: 74.9375em) {
  .mainNavigation ul li ul li {
    max-width: 33.3333333333%;
    flex-basis: 33.3333333333%;
  }
}
.mainNavigation ul li ul li a {
  font-size: 16px;
  font-size: 1rem;
  color: #fff;
  text-transform: none;
  font-weight: 300;
}
.mainNavigation ul li ul li a:hover {
  color: #fff;
  text-decoration: underline;
}

.noscroll-menu {
  height: 100%;
  overflow: hidden !important;
  width: 100%;
}

.no-scroll {
  overflow-y: hidden;
}

.responsiveHeaderWrap {
  display: none !important;
}
@media (max-width: 63.9375em) {
  .responsiveHeaderWrap {
    display: block !important;
  }
}
.responsiveHeaderWrap .responsiveHeaderBtnWrap {
  position: relative;
}
.responsiveHeaderWrap .responsiveHeaderBtnWrap .appointmentBtn {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  display: inline-block;
  background: #242424;
  font-size: 16px;
  font-size: 1rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  padding: 16px 25px 0;
  border: none;
}
.responsiveHeaderWrap .responsiveHeaderBtnWrap .appointmentBtn i {
  max-width: 16px;
  display: inline-block;
  margin-right: 3px;
}
.responsiveHeaderWrap .responsiveHeaderBtnWrap .appointmentBtn i img {
  vertical-align: text-top;
  margin-top: 1px;
}
.responsiveHeaderWrap .responsiveHeaderBtnWrap .topBtn {
  flex: 1;
  height: 50px;
  text-align: left;
  line-height: 3.5;
  background: #2f2f2f;
  border-bottom: 1px solid #2f2f2f;
  color: #fff;
  padding: 0 15px;
  font-weight: 500;
  text-transform: uppercase;
}
.responsiveHeaderWrap .responsiveHeaderBtnWrap .topBtn.activeNav {
  background: #2f2f2f;
}
.responsiveHeaderWrap .responsiveHeaderBtnWrap .topBtn.activeNav span {
  background: transparent;
}
.responsiveHeaderWrap .responsiveHeaderBtnWrap .topBtn.activeNav span:before {
  margin-top: 0;
  background: #fff;
  transform: rotate(45deg);
}
.responsiveHeaderWrap .responsiveHeaderBtnWrap .topBtn.activeNav span:after {
  margin-top: 0;
  background: #fff;
  transform: rotate(-45deg);
}
.responsiveHeaderWrap .responsiveHeaderBtnWrap .topBtn span {
  position: relative;
  width: 20px;
  height: 2px;
  background: #fff;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-top: -5px;
  transition: all 0.3s ease;
}
.responsiveHeaderWrap .responsiveHeaderBtnWrap .topBtn span:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  margin-top: -8px;
  background: #fff;
  transition: all 0.3s ease;
}
.responsiveHeaderWrap .responsiveHeaderBtnWrap .topBtn span:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  margin-top: 8px;
  background: #fff;
  transition: all 0.3s ease;
}
.responsiveHeaderWrap .responsiveNavWrap {
  background: rgba(0, 0, 0, 0.9);
  position: relative;
  width: 100%;
  overflow-y: auto;
  display: none;
}
.responsiveHeaderWrap .responsiveNavWrap.activeNav {
  display: block;
}
.responsiveHeaderWrap .responsiveNavWrap ul {
  text-align: center;
  background: #37383a;
}
.responsiveHeaderWrap .responsiveNavWrap ul li {
  display: list-item;
  border-bottom: 1px solid #403f3f;
  background: #37383a;
  animation: smooth-entry 0.4s;
}
.responsiveHeaderWrap .responsiveNavWrap ul li a {
  position: relative;
  display: block;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  text-align: left;
}
.responsiveHeaderWrap .responsiveNavWrap ul li a span {
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  color: #fff;
}
.responsiveHeaderWrap .responsiveNavWrap ul li ul {
  border-top: 1px solid #8c4e29;
  background: #683313;
}
.responsiveHeaderWrap .responsiveNavWrap ul li ul li {
  background: #683313;
  border-bottom: none;
  border-bottom: 1px solid #8c4e29;
}
.responsiveHeaderWrap .responsiveNavWrap ul li ul li:last-of-type {
  border-bottom: none;
}
.responsiveHeaderWrap .responsiveNavWrap ul li ul li a {
  position: relative;
  padding: 10px 20px 10px 40px;
}
.responsiveHeaderWrap .responsiveNavWrap ul li ul li a:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 20px;
  width: 10px;
  height: 1px;
  background: #fff;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 110;
  background: rgba(0, 0, 0, 0.9);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0s 0.3s;
}
.overlay.activeNav {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s;
}

html.activeNav {
  position: fixed;
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.bgvideoWrap {
  overflow: hidden;
  position: relative;
  min-height: 400px;
  max-height: 470px;
}
@media (max-width: 63.9375em) {
  .bgvideoWrap {
    margin-top: 0;
  }
}
.bgvideoWrap:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%;
}
.bgvideoWrap .videoContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.bgvideoWrap .videoContent .videoBgImg {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  background-size: cover;
  position: relative;
}
.bgvideoWrap .videoContent .videoBgImg .downAnchor {
  height: 64px;
  width: 64px;
  left: 50%;
  position: absolute;
  z-index: 10;
  display: block;
  transform: translate(-50%, 0);
  bottom: 40px;
  padding: 0;
}
@media (max-width: 33.6875em) {
  .bgvideoWrap .videoContent .videoBgImg .downAnchor {
    width: 40px;
    height: 40px;
  }
}
.bgvideoWrap .videoContent .videoBgImg .videoOverlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.bgvideoWrap .videoContent .videoBgImg .textContent {
  position: relative;
  z-index: 3;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  max-width: 80%;
  font-family: "Roboto";
  text-align: center;
  color: #fff;
  line-height: 1.3;
}
.bgvideoWrap .videoContent .videoBgImg .sliderBtn {
  border-radius: 5px;
  display: inline-block;
  padding: 12px 30px 10px;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  margin-top: 4px;
  background: #fff;
  border-bottom: 2px solid #242424;
}
@media (max-width: 47.9375em) {
  .bgvideoWrap .videoContent .videoBgImg .sliderBtn {
    padding: 10px 20px 8px;
  }
}
.bgvideoWrap .bgVideo {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.bgvideoWrap .bgVideo video {
  height: auto;
  left: 50%;
  min-height: 101%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  width: auto;
  z-index: 1;
  transform: translate(-50%, -50%);
}

.textSmall {
  font-size: 2.5vw;
  line-height: 1.2;
  text-transform: uppercase;
}
@media (max-width: 33.6875em) {
  .textSmall {
    font-size: 24px;
  }
}

.textLarge {
  font-size: 3.2vw;
  line-height: 1.5;
  text-shadow: 2px 2px #242424;
  text-transform: uppercase;
}
@media (max-width: 33.6875em) {
  .textLarge {
    font-size: 30px;
  }
}

.homeTabsWrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
}
@media (max-width: 47.9375em) {
  .homeTabsWrapper {
    padding-top: 15px;
    justify-content: space-between;
  }
}
.homeTabsWrapper .homeTab {
  position: relative;
  display: block;
  max-width: 32.5203252033%;
  flex-basis: 32.5203252033%;
  margin-right: 1.2195121951%;
  margin-bottom: 30px;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
}
@media (max-width: 47.9375em) {
  .homeTabsWrapper .homeTab {
    max-width: 49%;
    flex-basis: 49%;
    margin-right: 0;
    margin-bottom: 15px;
  }
}
@media (max-width: 29.9375em) {
  .homeTabsWrapper .homeTab {
    max-width: 400px;
    flex-basis: 100%;
    margin: 0 auto 15px;
    background: #2f2f2f;
    padding-bottom: 15px;
    border-radius: 5px;
  }
}
.homeTabsWrapper .homeTab:nth-child(3n) {
  margin-right: 0;
}
@media (max-width: 39.9375em) {
  .homeTabsWrapper .homeTab:nth-child(3n) {
    margin-right: 0;
  }
}
.homeTabsWrapper .homeTab:hover .tabHeading {
  text-decoration: underline;
}
.homeTabsWrapper .homeTab .imgWrap {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}
.homeTabsWrapper .homeTab .imgWrap img {
  transition: all 0.3s ease;
}
.homeTabsWrapper .homeTab .tabHeading {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 400;
  color: #5b6267;
  padding: 5px 0;
}
@media (max-width: 79.9375em) {
  .homeTabsWrapper .homeTab .tabHeading {
    font-size: 22px;
    font-size: 1.375rem;
  }
}
@media (max-width: 63.9375em) {
  .homeTabsWrapper .homeTab .tabHeading {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media (max-width: 29.9375em) {
  .homeTabsWrapper .homeTab .tabHeading {
    color: #fff;
  }
}
.homeTabsWrapper .homeTab .tabSubtext {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 400;
  color: #5b6267;
}
@media (max-width: 79.9375em) {
  .homeTabsWrapper .homeTab .tabSubtext {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media (max-width: 29.9375em) {
  .homeTabsWrapper .homeTab .tabSubtext {
    color: #fff;
  }
}

.homeTextWrap {
  text-align: center;
}

.pageSection {
  position: relative;
  padding: 50px 0 60px;
}
@media (max-width: 63.9375em) {
  .pageSection {
    padding: 40px 0;
  }
}
@media (max-width: 47.9375em) {
  .pageSection {
    padding: 30px 0;
  }
}
.pageSection.pageVideoSection, .pageSection.sectionParallax {
  padding: 0;
}
.pageSection.bgBeige {
  background: #fff3db;
}
.pageSection.bgWhite {
  background: #fff;
}
.pageSection.bgLightGray {
  background: #f3f3f3;
}
.pageSection.bgGray {
  background: #d8d9d3;
}
.pageSection .textHeadingWrap {
  padding-bottom: 30px;
  font-size: 36px;
  font-size: 2.25rem;
}
@media (max-width: 63.9375em) {
  .pageSection .textHeadingWrap {
    font-size: 34px;
    font-size: 2.125rem;
  }
}
@media (max-width: 47.9375em) {
  .pageSection .textHeadingWrap {
    font-size: 28px;
    font-size: 1.75rem;
  }
}
.pageSection .textHeadingWrap .textHeading {
  font-size: 36px;
  font-size: 2.25rem;
  font-weight: 500;
  color: #5b6267;
  text-align: center;
  line-height: 1.4;
}
@media (max-width: 63.9375em) {
  .pageSection .textHeadingWrap .textHeading {
    font-size: 34px;
    font-size: 2.125rem;
  }
}
@media (max-width: 47.9375em) {
  .pageSection .textHeadingWrap .textHeading {
    font-size: 28px;
    font-size: 1.75rem;
  }
}
.pageSection .textHeadingWrap .textSubHeading {
  font-size: 62%;
  color: #5b6267;
  line-height: 1.3;
  display: block;
  text-align: center;
}
.pageSection p {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.7;
  font-weight: 300;
  padding-bottom: 20px;
  color: #5b6267;
}
@media (max-width: 79.9375em) {
  .pageSection p {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media (max-width: 47.9375em) {
  .pageSection p {
    font-size: 16px;
    font-size: 1rem;
  }
}
.pageSection p a {
  font-weight: 500;
  padding-bottom: 2px;
  border-bottom: 1px solid #5b6267;
  text-decoration: none;
  color: #5b6267;
}
.pageSection p a.callNumber {
  text-decoration: none;
  padding-bottom: 0;
  border-bottom: none !important;
}
.pageSection p a:hover {
  border-bottom: 1px solid transparent;
}
.pageSection .textCenter {
  text-align: center;
}

.pageSectionList {
  text-align: center;
  margin-bottom: 50px;
}
.pageSectionList.zeroBottomMargin {
  margin-bottom: 0;
}
@media (max-width: 47.9375em) {
  .pageSectionList {
    margin-bottom: 25px;
  }
}
.pageSectionList li {
  padding: 15px 0;
  border-bottom: 1px dashed #cfcfcf;
}

.bgGrayAccreditations {
  background: #d8d9d3;
  padding: 20px 0;
}

.commonContentWrapper {
  text-align: center;
}
.commonContentWrapper h2, .commonContentWrapper h3, .commonContentWrapper h4 {
  font-size: 24px;
  color: #5b6267;
  margin-bottom: 20px;
}

.subpageBannerSection {
  border-top: 1px solid #fff;
}

.subpageBannerWrapper {
  position: relative;
  margin: auto;
  overflow: hidden;
  min-height: 400px;
  max-height: 470px;
}
.subpageBannerWrapper.gulnaraBanner {
  background: #000 url("../images/about-bg.jpg") center center no-repeat;
  background-size: cover;
}
.subpageBannerWrapper.massageTherapies {
  background: #000 url("../images/massage-therapies.jpg") center center no-repeat;
  background-size: cover;
}
.subpageBannerWrapper.fullBodyMassage {
  background: #000 url("../images/full-body-large.jpg") center center no-repeat;
  background-size: cover;
}
.subpageBannerWrapper.backNeckMassage {
  background: #000 url("../images/back-neck-large.jpg") center center no-repeat;
  background-size: cover;
}
.subpageBannerWrapper.reviewsBanner {
  background: #000 url("../images/reviews-large.jpg") center center no-repeat;
  background-size: cover;
}
.subpageBannerWrapper.contactBanner {
  background: #000 url("../images/contact-bg.jpg") center center no-repeat;
  background-size: cover;
}
.subpageBannerWrapper .subpageBannerContentWrap {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.subpageBannerWrapper .subpageBannerContentWrap .textContent {
  position: relative;
  z-index: 3;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  max-width: 80%;
  font-family: "Roboto";
  text-align: center;
  color: #fff;
  line-height: 1.3;
}
.subpageBannerWrapper .subpageBannerContentWrap .sliderBtn {
  border-radius: 5px;
  display: inline-block;
  padding: 12px 30px 10px;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  margin-top: 4px;
  background: #fff;
  border-bottom: 2px solid #242424;
}
@media (max-width: 47.9375em) {
  .subpageBannerWrapper .subpageBannerContentWrap .sliderBtn {
    padding: 10px 20px 8px;
  }
}
.massageTherapiesWrap {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  flex-basis: 1000px;
  justify-content: space-between;
  margin: auto;
}
@media (max-width: 37.4375em) {
  .massageTherapiesWrap {
    justify-content: center;
  }
}
.massageTherapiesWrap .massageTherapiesTab {
  position: relative;
  max-width: 45%;
  flex-basis: 45%;
  min-height: 200px;
}
@media (max-width: 37.4375em) {
  .massageTherapiesWrap .massageTherapiesTab {
    max-width: 450px;
    flex-basis: 450px;
    margin-bottom: 50px;
    border-radius: 5px;
  }
  .massageTherapiesWrap .massageTherapiesTab:last-child {
    margin-bottom: 0;
  }
}
.massageTherapiesWrap .massageTherapiesTab .therapyName {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 15px;
  font-size: 30px;
  font-size: 1.875rem;
  color: #fff;
  font-weight: 700;
}
@media (max-width: 37.4375em) {
  .massageTherapiesWrap .massageTherapiesTab .therapyName {
    border-radius: 5px;
  }
}

.therapyPrice {
  padding-top: 20px;
  font-size: 30px;
  font-size: 1.875rem;
  text-align: center;
  color: #5b6267;
  font-weight: 700;
}

.reputationTextWrap {
  padding: 20px 0 40px 0;
}
@media (max-width: 39.9375em) {
  .reputationTextWrap {
    padding: 0 0 25px 0;
  }
}
.reputationTextWrap .reputationTextBlock {
  position: relative;
  padding: 30px 0;
  border-bottom: 2px dotted #b5b3b3;
  text-align: center;
}
.reputationTextWrap .reputationTextBlock:first-of-type {
  padding: 0 0 30px 0;
}
@media (max-width: 39.9375em) {
  .reputationTextWrap .reputationTextBlock:first-of-type {
    padding: 0 0 30px 0;
  }
}
@media (max-width: 39.9375em) {
  .reputationTextWrap .reputationTextBlock {
    padding: 30px 0;
  }
}
.reputationTextWrap .reputationTextBlock .title {
  font-size: 36px;
  font-size: 2.25rem;
  color: #5b6267;
  font-weight: 700;
}
@media (max-width: 79.9375em) {
  .reputationTextWrap .reputationTextBlock .title {
    font-size: 30px;
    font-size: 1.875rem;
  }
}
@media (max-width: 63.9375em) {
  .reputationTextWrap .reputationTextBlock .title {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
@media (max-width: 39.9375em) {
  .reputationTextWrap .reputationTextBlock .title {
    font-size: 22px;
    font-size: 1.375rem;
  }
}
.reputationTextWrap .reputationTextBlock .date {
  font-size: 22px;
  font-size: 1.375rem;
  color: #a9a9a9;
}
.reputationTextWrap .reputationTextBlock .reputationText {
  padding: 20px 0 20px;
}
@media (max-width: 39.9375em) {
  .reputationTextWrap .reputationTextBlock .reputationText {
    padding: 15px 0 20px;
  }
}
.reputationTextWrap .reputationTextBlock .authorName {
  font-size: 22px;
  font-size: 1.375rem;
  color: #5b6267;
}

.imageWrap {
  position: relative;
  text-align: center;
}
.imageWrap .imageHeading {
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: 700;
  color: #5b6267;
  padding: 30px 0 20px 0;
}
.imageWrap .imageBlock {
  padding: 0 0 10px 0;
}

.pageFooter {
  position: relative;
  background: #fff;
  padding: 50px 0;
  border: 1px solid #dedede;
}
@media (max-width: 39.9375em) {
  .pageFooter {
    padding: 30px 0;
  }
}
.pageFooter .footerNavWrap {
  position: relative;
  margin-bottom: 15px;
}
.pageFooter .footerNavWrap .footerNav {
  text-align: center;
}
.pageFooter .footerNavWrap .footerNav li {
  display: inline-block;
  border-right: 1px solid #5b6267;
  line-height: 1;
}
.pageFooter .footerNavWrap .footerNav li:last-child {
  border-right: 0;
}
.pageFooter .footerNavWrap .footerNav li a {
  color: #5b6267;
  text-decoration: none;
  padding: 0 10px;
}
.pageFooter .footerNavWrap .footerNav li a:hover {
  text-decoration: underline;
}
.pageFooter .copyright {
  text-align: center;
  color: #5b6267;
}
.pageFooter .creditTabWrapper {
  padding: 15px 0 0;
  display: flex;
  flex-wrap: wrap;
}
.pageFooter .creditTabWrapper .creditDesignDev {
  flex-basis: 100%;
  max-width: 100%;
  font-size: 14px;
  font-size: 0.875rem;
  color: #5b6267;
  font-weight: 400;
  text-align: center;
}
@media (max-width: 33.6875em) {
  .pageFooter .creditTabWrapper .creditDesignDev {
    flex-basis: 100%;
    max-width: 100%;
    text-align: center;
  }
}
.pageFooter .creditTabWrapper .creditDesignDev a {
  font-size: 14px;
  font-size: 0.875rem;
  color: #5b6267;
  font-weight: 400;
  text-decoration: none;
  padding-bottom: 2px;
  border-bottom: 1px solid transparent;
}
.pageFooter .creditTabWrapper .creditDesignDev a:hover {
  border-bottom: 1px solid #5b6267;
}

*::-moz-selection {
  background: none repeat scroll 0 0 rgb(23, 119, 175);
  color: rgb(250, 250, 250);
  text-shadow: none;
}

input:-moz-placeholder {
  font-size: 0.875em;
  line-height: 1.4;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
}

input[type=search] {
  box-sizing: content-box;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0 none;
  padding: 0;
}

p {
  /*-moz-hyphens: auto;*/
}

@media print {
  * {
    background: none repeat scroll 0 0 transparent !important;
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important;
  }
  a, a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
    content: "";
  }
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr, img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }
  h2, h3 {
    page-break-after: avoid;
  }
}