@import 'modules/mixin';

.massageTherapiesWrap{
	display: flex;
	flex-wrap: wrap;
	max-width: 1000px;
	flex-basis: 1000px; 
	justify-content: space-between;
	margin: auto;

	@include MQ(599) {
		justify-content: center;
	}

	.massageTherapiesTab{
		position: relative;
		max-width: 45%;
		flex-basis: 45%;
		min-height: 200px;

		@include MQ(599) {
			max-width: 450px;
			flex-basis: 450px;
			margin-bottom: 50px;
			border-radius: 5px;

			&:last-child{
				margin-bottom: 0;
			}
		}

		.therapyName{
			position: absolute;
			// width: 100%;
			// height: 100%;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			text-align: center;			
			background: rgba(#000, 0.7);
			padding: 15px;
			@include font-size(30px);
			color: #fff;
			font-weight: 700;

			@include MQ(599) {
				border-radius: 5px;
			}
		}
	}
}

.therapyPrice{
	padding-top: 20px;
	@include font-size(30px);
	text-align: center;
	color: #5b6267;
	font-weight: 700;
}