@import 'mixin';

.bgvideoWrap{
    // margin-top: 153px;
    // max-height: calc(100vh - 143px);
    // max-height: calc(100vh - 143px);
    overflow: hidden;
    position: relative;
    // background: #152855;
    min-height: 400px;
    max-height: 470px;
    // margin-top: -50px;

    @include MQ(1023) {
        margin-top: 0;
        // margin-top: -40px;
    }

    @include MQ(767) {
        // margin-top: -30px;
    }

    @include MQ(539) {
        // margin-top: 113px;
    }

    &:before{
        display: block;
        content: "";
        width: 100%;
        padding-top: 56.25%;
    }

    .videoContent{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .videoBgImg{
            height: 100%;
            width: 100%;
            background: rgba(#000, 0.7);
            background-size: cover;
            position: relative;

            .downAnchor{
                height: 64px;
                width: 64px;
                left: 50%;
                position: absolute;
                z-index: 10;
                display: block;
                transform: translate(-50%, 0);
                bottom: 40px;
                padding: 0;

                @include MQ(539) {
                    width: 40px;
                    height: 40px;
                }
            }

            .videoOverlay{
                width: 100%;
                height: 100%;
                background: rgba(#000, 0.5);
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
            }
            .textContent{
                position: relative;
                z-index: 3;
                margin: 0 auto;
                top: 50%;
                transform: translateY(-50%);
                max-width: 80%;
                font-family: 'Roboto';
                text-align: center;
                color: #fff;
                // font-size: 5vw;
                line-height: 1.3;

            }
            .sliderBtn{
                border-radius: 5px;
                display: inline-block;
                padding: 12px 30px 10px;
                color: #000;
                text-decoration: none;
                text-transform: uppercase;
                text-align: center;

                margin-top: 4px;
                background: #fff;
                border-bottom: 2px solid #242424;

                @include MQ(767) {
                    padding: 10px 20px 8px;
                }

                &:hover{
                    
                }
            }
        }
    }
    .bgVideo{
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;

        video{
            height: auto;
            left: 50%;
            min-height: 101%;
            min-width: 100%;
            position: absolute;
            top: 50%;
            width: auto;
            z-index: 1;
            transform: translate(-50%, -50%);
            // max-height: 101%;
        }
    }
}

.textSmall {
    font-size: 2.5vw;    
    line-height: 1.2;
    // font-weight: 100;
    text-transform: uppercase;

    @include MQ(539) {
        font-size: 24px;
    }
}

.textLarge{
    font-size: 3.2vw;    
    line-height: 1.5;
    // font-weight: 300;
    text-shadow: 2px 2px #242424;
    text-transform: uppercase;

    @include MQ(539) {
        font-size: 30px;
    }
}