@import 'modules/mixin';

.pageFooter{
    position: relative;
    background: #fff;
    // padding: 80px 0 50px 0;
    padding: 50px 0;
    border: 1px solid #dedede;

    @include MQ(639) {
        // padding: 50px 0 30px;
        padding: 30px 0;
    }
    .footerNavWrap{
        position: relative;
        margin-bottom: 15px;

        .footerNav{
            text-align: center;

            li{
                display: inline-block;
                border-right: 1px solid #5b6267;    
                line-height: 1;

                &:last-child {
                    border-right: 0;
                }

                a{
                    color: #5b6267;
                    text-decoration: none;
                    padding: 0 10px;
                    // line-height: 1;
                                    

                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    

    .copyright{
        text-align: center;
        color: #5b6267;
    }
    .creditTabWrapper{
        padding: 15px 0 0;
        display: flex;
        flex-wrap: wrap;

        .creditDesignDev{
            flex-basis: 100%;
            max-width: 100%;
            @include font-size(14px);
            color: #5b6267;
            font-weight: 400;
            text-align: center;

            @include MQ(539) {
                flex-basis: 100%;
                max-width: 100%;
                text-align: center;
                // padding-top: 10px;
            }

            a{
                @include font-size(14px);
                color: #5b6267;
                font-weight: 400;
                text-decoration: none;
                padding-bottom: 2px;
                border-bottom: 1px solid transparent;

                &:hover{
                    border-bottom: 1px solid #5b6267;
                } 
            }
        }
    }
}
