@import "mixin";

.headerCallNumber {
  position: absolute;
  top: 15px;
  right: 30px;

  @include MQ(1199) {
    // position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    text-align: center;
    background: #fff3db;
    padding: 5px;
    border-bottom: 1px solid #ebdec6;
  }

  @include MQ(1023) {
    display: none !important;
  }

  a {
    text-decoration: none !important;
  }

  i {
    max-width: 20px;
    display: inline-block;
    margin-right: 0;

    img {
      vertical-align: text-top;
      margin-top: -2px;
    }
  }
  span {
    font-size: 20px;
    color: #783d19;
  }
}

.pageHeader {
  background: #5b6267;
  border-bottom: 2px solid #fff;

  @include MQ(539) {
    .wrapper {
      padding: 0;
    }
  }

  .headerTop {
    padding: 35px 0;
    display: flex;
    // align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    // border-bottom: 1px solid #e8e8e8;

    // @include MQ(1199) {
    // 	padding: 60px 0 0;
    // }
    // @include MQ(1023) {
    // 	padding: 25px 0 0;
    // }

    @include MQ(539) {
      padding: 20px 0 0;
    }

    .logoWrap {
      // @include MQ(1199) {
      // 	max-width: 100%;
      // 	flex-basis: 100%;
      // 	text-align: center;
      // }

      @include MQ(1023) {
        max-width: 165px;
      }
      @include MQ(539) {
        max-width: 100%;
        flex-basis: 100%;
        text-align: center;
        margin-bottom: 15px;

        img {
          max-width: 165px;
        }
      }

      @include MQ(479) {
        a {
          max-width: 250px;
          display: block;
          margin: auto;
        }
      }
    }
    .headerRight {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include MQ(1023) {
        justify-content: center;
      }

      @include MQ(539) {
        display: none;
        max-width: 100%;
        flex-basis: 100%;
        // background: #5b6267;
        // border-top: 1px solid #646d73;
        background: #2f2f2f;
        padding: 15px 0;
      }

      .headerNumberWrap {
        text-align: right;
        padding-right: 18px;

        @include MQ(539) {
          padding: 0 0 0 0;
          text-align: center;
        }

        .headerNumber {
          @include font-size(24px);
          color: #bfc5c6;
          line-height: 1;
          text-decoration: none;

          span {
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 0.03em;
            display: block;
          }
        }
      }

      .navWrapper {
        // flex: 1;

        // @include MQ(1199) {
        // 	max-width: 100%;
        // 	flex-basis: 100%;
        // }
      }
    }
  }
}
