@import 'modules/mixin';

.mainNavigation{
	position: relative;
	

	// @include MQ(1199) {
	// 	// margin-top: 40px;
	// 	// background: #f4f4f4;
	// 	background: #fff3db;
	// 	border-top: 3px solid #cf8727;
	// }

	@include MQ(1023) {
		display: none;
	}

	ul{
		text-align: right;

		@include MQ(1199) {
			text-align: center;
		}
		
		li{
			display: inline-block;
			&.current-menu-item{

				a{
					background: #783d19;
					color: #fff;
				}
			}
			&:hover{
				a{
					background: #783d19;
					color: #fff;
				}

				ul{
					opacity: 1;
					display: flex;
					z-index: 100;
				}
			}

			a{
				position: relative;
				display: block;
				@include font-size(14px);
				text-transform: uppercase;
				font-weight: 500;
				color: #bfc5c6;
				padding: 10px 20px;
				text-decoration: none;
				text-align: left;

				@include MQ(1199) {
					// @include font-size(20px);
					padding: 8px 20px;
				}

				&.current-menu-item{
					background: #bfc5c6;
					color: #000;
				}

				
				&:hover{
					background: #bfc5c6;
					color: #000;
				}
				&:hover ul{
					opacity: 1;
					display: flex;
					z-index: 100;
				}
	
			}

			ul{
				position: absolute;
				right: 0;
				width: 100%;
				background: #783d19;
				padding: 20px;
				list-style: none;
				
				text-align: left;
				z-index: 100;
				// display: none;
				display: flex;
				flex-wrap: wrap;

				opacity: 0;
				transition: opacity 0.5s ease;

				z-index: -100;

				li{
					max-width: 50%;
					flex-basis: 50%;

					@include MQ(1199) {
						max-width: 33.33333333333333%;
						flex-basis: 33.33333333333333%;
					}

					a{
						@include font-size(16px);
						color: #fff;
						text-transform: none;
						font-weight: 300;
						// padding:20px;

						&:hover{
							color: #fff;	
							text-decoration: underline;					
						}
					}
				}
			}
		}
	}
}