@import 'mixin';

.subpageBannerSection{
	border-top: 1px solid #fff;
}
.subpageBannerWrapper{
	position: relative;
	// max-width: 1230px;
	// max-width: 76.875em;
	margin: auto;
	overflow: hidden;
    min-height: 400px;
    max-height: 470px;

    &.gulnaraBanner{
        background: #000 url('../images/about-bg.jpg') center center no-repeat;
        background-size: cover;
    }

    &.massageTherapies{
    	background: #000 url('../images/massage-therapies.jpg') center center no-repeat;
    	background-size: cover;
    }
    &.fullBodyMassage{
        background: #000 url('../images/full-body-large.jpg') center center no-repeat;
        background-size: cover;
    }
    &.backNeckMassage{
        background: #000 url('../images/back-neck-large.jpg') center center no-repeat;
        background-size: cover;
    }
    &.reviewsBanner{
        background: #000 url('../images/reviews-large.jpg') center center no-repeat;
        background-size: cover;
    }
    &.contactBanner{
        background: #000 url('../images/contact-bg.jpg') center center no-repeat;
        background-size: cover;
    }

    .subpageBannerContentWrap{
        height: 100%;
        width: 100%;
        background: rgba(#000000, 0.7);
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .textContent{
            position: relative;
            z-index: 3;
            margin: 0 auto;
            top: 50%;
            transform: translateY(-50%);
            max-width: 80%;
            font-family: 'Roboto';
            text-align: center;
            color: #fff;
            // font-size: 5vw;
            line-height: 1.3;

        }
        .sliderBtn{
            border-radius: 5px;
            display: inline-block;
            padding: 12px 30px 10px;
            color: #000;
            text-decoration: none;
            text-transform: uppercase;
            text-align: center;

            margin-top: 4px;
            background: #fff;
            border-bottom: 2px solid #242424;

            @include MQ(767) {
                padding: 10px 20px 8px;
            }

            &:hover{
                
            }
        }
    }
}