@import 'modules/mixin';

.noscroll-menu {
    height: 100%;
    overflow: hidden!important;
    width: 100%;
}
.no-scroll {
    overflow-y: hidden;
}

.responsiveHeaderWrap{
    // position: relative;
    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;
    // width: 100%;
    // background: #000;
    display: none !important;
    // z-index: 200;
    // overflow-y: scroll;

    @include MQ(1023) {
        display: block !important;
    }

    .responsiveHeaderBtnWrap{
        // position: fixed;
        // top: 0;
        // left: 0;
        // width: 100%;
        // z-index: 200;
        // display: flex;
        position: relative;

        .appointmentBtn {
            position: absolute;
            right: 0;
            top: 0;
            height: 50px;
            display: inline-block;
            // max-width: 130px;
            // width: 100%;
            background: #242424;

            @include font-size(16px);
            color: #fff;
            text-transform: uppercase;
            text-align: center;
            text-decoration: none;
            font-weight: 500;
            // border-radius: 5px;
            padding: 16px 25px 0;
            border: none;

            i{
                max-width: 16px;
                display: inline-block;
                margin-right: 3px;

                img{
                    vertical-align: text-top;
                    margin-top: 1px;
                }
            }
        }

        .topBtn{
            flex: 1;
            height: 50px;
            text-align: left;
            line-height: 3.5;
            background: #2f2f2f;
            border-bottom: 1px solid #2f2f2f;
            color: #fff;
            padding: 0 15px;
            font-weight: 500;
            text-transform: uppercase;

            &.activeNav{
                background: #2f2f2f;

                span{
                    background: transparent;

                    &:before{
                        margin-top: 0;
                        background: #fff;
                        transform: rotate(45deg);
                    }
                    &:after{
                        margin-top: 0;
                        background: #fff;
                        transform: rotate(-45deg);
                    }
                }
            }

            span{
                position: relative;
                width: 20px;
                height: 2px;
                background: #fff;
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
                margin-top: -5px;
                transition: all 0.3s ease;

                &:before{
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: '';
                    display: block;
                    width: 100%;
                    height: 2px;
                    margin-top: -8px;
                    background: #fff;
                    transition: all 0.3s ease;
                }
                &:after{
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: '';
                    display: block;
                    width: 100%;
                    height: 2px;
                    margin-top: 8px;
                    background: #fff;
                    transition: all 0.3s ease;
                }
            }
        }
    }
    .responsiveNavWrap{
        background: rgba(#000, 0.9);
        position: relative;
        // position: fixed;
        // top: 50px;
        width: 100%;
        // @include calc( height, '100vh - 50px');
        // z-index: 200;
        // overflow-y: scroll;
        overflow-y: auto;

        display: none;

        &.activeNav{
            display: block;
        }

        ul{
            // position: absolute;
            // overflow: scroll;
            text-align: center;
            background: #37383a;
            
            li{
                display: list-item;
                border-bottom: 1px solid #403f3f;
                background: #37383a;
                animation: smooth-entry 0.4s;

                &.current-menu-item{

                    // a{
                    //     background: #a9091c;
                    //     color: #fff;
                    // }
                }

                a{
                    position: relative;
                    display: block;
                    @include font-size(16px);
                    font-weight: 400;
                    color: #fff;
                    padding: 10px 20px;
                    text-decoration: none;
                    text-align: left;
                    
                    
                    // &:hover{
                    //     background: #efae13;;
                    // }

                    span{
                        display: block;
                        @include font-size(14px);
                        color: #fff;
                    }
                
                }

                ul {
                    border-top: 1px solid #8c4e29;
                    background: #683313;

                    li{
                        background: #683313;
                        border-bottom: none;
                        border-bottom: 1px solid #8c4e29;

                        &.current-menu-item{

                            // a{
                            //     background: #a9091c;
                            //     color: #fff;
                            // }
                        }

                        &:last-of-type{
                            border-bottom: none;
                        }

                        a{
                            position: relative;
                            padding: 10px 20px 10px 40px;

                            &:before {
                                content: '';
                                position: absolute;
                                top: 50%;
                                left: 20px;
                                width: 10px;
                                height: 1px;
                                // margin-top: -1px;
                                background: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}

.overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 110;
    background: rgba(#000, 0.9);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0s 0.3s;

    &.activeNav{
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s;

        @include MQ(1280) {
            visibility: hidden !important;
        }
    }
}
html.activeNav{
    position: fixed;
    margin: 0; 
    height: 100%; 
    overflow: hidden;
}
