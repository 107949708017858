@import 'modules/_typography-variables';

body{
	background: #fff;
	font:normal 100%/1.5 $body-font;
	color:#fff;
	font-weight: 300;
	margin:0;
	text-align: left;
}
.wrapper {
	position:relative;
	width:100%;
	max-width:80em; /*1280/16*/
	padding: 0 15px;
	margin:0 auto;
}
.container{
	width: 100%;
	background: #fff;
	padding-bottom: 50px;
}