@import 'modules/mixin';

.homeTabsWrapper{
	position: relative;
	display: flex;
	flex-wrap: wrap;
	padding-top: 30px;

	@include MQ(767) {
		padding-top: 15px;
		justify-content: space-between;
	}

	.homeTab{
		position: relative;
		display: block;
		max-width: 32.52032520325203%; // 400 / 1230
		flex-basis: 32.52032520325203%;
		margin-right: 1.21951219512195%; // 15 / 1230 
		margin-bottom: 30px;
		text-decoration: none;
		text-align: center;
		overflow: hidden;
		// cursor: pointer;

	
		@include MQ(767) {
			max-width: 49%;
			flex-basis: 49%;
			margin-right: 0;
			margin-bottom: 15px;
		}

		@include MQ(479) {
			max-width: 400px;
			flex-basis: 100%;
			margin: 0 auto 15px;
			background: #2f2f2f;
			padding-bottom: 15px;
			border-radius: 5px;
		}

		&:nth-child(3n) {
			margin-right: 0;

			@include MQ(639) {
				margin-right: 0;
			}
		}
		&:hover{
			// .imgWrap{
			// 	&:before {
			// 		bottom: 100%;
			// 		height: 0;
			// 	}
			// }
			// img{
			// 	transform: scale(1.1);
			// }

			.tabHeading {
				text-decoration: underline;
			}
		}

		.imgWrap{
			position: relative;
			overflow: hidden;
			margin-bottom: 10px;
			

			// &:before{
			// 	content: '';
			// 	position: absolute;
			// 	bottom: 0;
			// 	left: 0;
			// 	right: 0;
			// 	height: 100%;
			// 	background: rgba(#000, 0.3);
			// 	transition: all 0.3s ease;
			// }

			img{
				transition: all 0.3s ease;
			}
		}
		.tabHeading{
			@include font-size(24px);
			font-weight: 400;
			color: #5b6267;
			padding: 5px 0;

			@include MQ(1279) {
				@include font-size(22px);
			}
			@include MQ(1023) {
				@include font-size(18px);
			}
			@include MQ(479) {
				color: #fff;
			}
		}
		.tabSubtext{
			@include font-size(18px);
			font-weight: 400;
			color: #5b6267;

			@include MQ(1279) {
				@include font-size(16px);
			}
			@include MQ(479) {
				color: #fff;
			}
		}
	}
}

.homeTextWrap{
	text-align: center;
}
