@import 'modules/mixin';

.reputationTextWrap{
	padding: 20px 0 40px 0;

	@include MQ(639) {
		padding: 0 0 25px 0;
	}

	.reputationTextBlock{
		position: relative;
		padding: 30px 0;
		border-bottom: 2px dotted #b5b3b3;
		text-align: center;

		&:first-of-type{
			padding: 0 0 30px 0;

			@include MQ(639) {
				padding: 0 0 30px 0;
			}
		}

		@include MQ(639) {
			padding: 30px 0;
		}

		.title{
			@include font-size(36px);
			color: #5b6267;
			font-weight: 700;
			// margin-bottom: 10px;

			@include MQ(1279) {
				@include font-size(30px);
			}
			@include MQ(1023) {
				@include font-size(24px);
				// margin-bottom: 5px;
			}
			@include MQ(639) {
				@include font-size(22px);
			}
		}
		.date{
			@include font-size(22px);
			color: #a9a9a9;
		}
		.reputationText{
			padding: 20px 0 20px;

			@include MQ(639) {
				padding: 15px 0 20px;
			}
		}
		.authorName{

			@include font-size(22px);
			color: #5b6267;
		}
	}
}