@import 'mixin';

.imageWrap{
	position: relative;
	text-align: center;

	.imageHeading{
		@include font-size(26px);
		font-weight: 700;
		color: #5b6267;
		padding: 30px 0 20px 0;
	}
	.imageBlock{
		padding: 0 0 10px 0;
	}
}