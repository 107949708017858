@import 'modules/mixin';

.pageSection{
	position: relative;
	padding: 50px 0 60px;

	@include MQ(1023) {
		padding: 40px 0;
	}
	@include MQ(767) {
		padding: 30px 0;
	}

	&.pageVideoSection,
	&.sectionParallax{
		padding: 0;
	}

	&.bgBeige{
		background: #fff3db;
	}
	&.bgWhite{
		background: #fff;
	}
	&.bgLightGray{
		background: #f3f3f3;
	}
	&.bgGray{
		background: #d8d9d3;
	}

	// &:first-of-type {
	// 	&:before{
	// 		background: transparent;
	// 	}
	// }

	// &:before{
	// 	position: absolute;
	// 	 left: 50%;
	// 	 transform: translateX(-50%);
	// 	 top: 0;
	// 	 content: '';
	// 	 width: 60px;
	// 	 height: 4px;
	// 	 background: #fff;
	// }
	// &.noDivider{
	// 	&:before{
	// 		background: transparent;
	// 	}
	// }
	// &.dividerHideOnMobileShowMoreBtn{
	// 	@include MQ(639) {
	// 		&:before{
	// 			background: transparent;
	// 		}
	// 	}
		
	// }


	.textHeadingWrap{
		padding-bottom: 30px;
		@include font-size(36px);

		@include MQ(1023) {
			@include font-size(34px);
		}
		@include MQ(767) {
			@include font-size(28px);
		}

		.textHeading{
			@include font-size(36px);
			font-weight: 500;	
			color: #5b6267;
			text-align: center;
			line-height: 1.4;

			@include MQ(1023) {
				@include font-size(34px);
			}
			@include MQ(767) {
				@include font-size(28px);
			}
		}
		.textSubHeading{
			// padding-top: 5px;
			font-size: 62%;
			// @include font-size(22px);
			color: #5b6267;
			line-height: 1.3;
			// font-weight: 300;	
			display: block;
			text-align: center;

			// @include MQ(1023) {
			// 	@include font-size(20px);
			// }
			// @include MQ(767) {
			// 	@include font-size(18px);
			// }
		}
	}
	p{
		@include font-size(20px);
		// color: #fff;
		line-height: 1.7;
		font-weight: 300;
		padding-bottom: 20px;
		color: #5b6267;

		@include MQ(1279) {
			@include font-size(18px);
		}
		@include MQ(767) {
			@include font-size(16px);
		}

		a{
			// color: #fff;
			font-weight: 500;
			padding-bottom: 2px;
			border-bottom: 1px solid #5b6267;
			text-decoration: none;
			color: #5b6267;

			&.callNumber{
				text-decoration: none;
				padding-bottom: 0;
				border-bottom: none !important;
			}

			&:hover{
				border-bottom: 1px solid transparent;
			}
		}
	}
	.textCenter{
		text-align: center;
	}
}

.pageSectionList{
	text-align: center;
	margin-bottom: 50px;

	&.zeroBottomMargin{
		margin-bottom: 0;
	}

	@include MQ(767) {
		margin-bottom: 25px;
	}

	li{
		padding: 15px 0;
		border-bottom: 1px dashed #cfcfcf;
	}
}

.bgGrayAccreditations{
	background: #d8d9d3;
	padding: 20px 0;
}
.commonContentWrapper{
	text-align: center;	

	h2,	h3,	h4 {
		font-size: 24px;
		color: #5b6267;
		margin-bottom: 20px;
	}
}
